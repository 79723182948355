/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery-1.8@1.8.3/jquery.min.js
 * - /gh/ahmst/ft-common@1.60/js/scripts.min.js
 * - /gh/ahmst/ft-common@1.60/js/diego/scripts.min.js
 * - /npm/ga-lite@2.1.6/dist/ga-lite.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
